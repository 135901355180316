import React from "react";
import { css } from "@emotion/core";

import styles from "../styles";

const Footer = ({ date }) => {
  return (
    <footer
      css={css`
        font-weight: 300;
        font-size: 0.75rem;
        color: ${styles.colors.gray500};
        padding: 1rem calc((100vw - 900px - 1.5rem) / 2);
      `}
    >
      <section
        css={css`
          font-weight: 100;
          padding: 1rem;
          text-align: center;
          border-top: 1px solid ${styles.colors.gray400};
        `}
      >
        Copyright &copy; {date.getFullYear()} conorfischer.com All rights
        reserved.
      </section>
    </footer>
  );
};

Footer.defaultProps = {
  date: new Date(),
};

export default Footer;
