import React from "react";
import styled from "@emotion/styled";
import styles from "../styles";

const ContentSection = styled.section`
  margin: 2rem 0 0;

  p {
    font-weight: 300;
    font-size: ${styles.text.sizebase};
  }
`;

const HeroSection = styled(ContentSection)`
  h2 {
    font-size: ${styles.text.size4xl};

    ${styles.screens.md} {
      font-size: ${styles.text.size5xl};
    }
  }

  h3 {
    margin: 2rem 0 0;
    font-size: ${styles.text.size3xl};
    font-weight: 300;
  }
`;

export { ContentSection, HeroSection };
