export default {
  colors: {
    white: "#ffffff",
    black: "#000000",
    primary: "#3e65ff",
    primaryVariant: "#0031e3",
    secondary: "#ffd83e",
    secondaryVariant: "#faa92f",
    gray50: "#fdfdfd",
    gray100: "#f5f5f5",
    gray200: "#eeeeee",
    gray300: "#e0e0e0",
    gray400: "#bdbdbd",
    gray500: "#9e9e9e",
    gray600: "#757575",
    gray700: "#616161",
    gray800: "#424242",
    gray900: "#212121",
  },
  screens: {
    sm: "@media (min-width: 640px)",
    md: "@media (min-width: 768px)",
    lg: "@media (min-width: 1024px)",
    xl: "@media (min-width: 1280px)",
  },
  text: {
    sizexs: ".75rem",
    sizesm: ".875rem",
    sizebase: "1rem",
    sizelg: "1.125rem",
    sizexl: "1.25rem",
    size2xl: "1.5rem",
    size3xl: "1.875rem",
    size4xl: "2.25rem",
    size5xl: "3rem",
    size6xl: "4rem",
  },
  shadow: {
    sm: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    md: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
    lg: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
    xlg: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
  },
};
