import React from "react";
import Helmet from "react-helmet";
import { Global, css } from "@emotion/core";

import styles from "../styles";
import Header from "./header";
import Footer from "./footer";

const globalStyles = css`
  * {
    box-sizing: border-box;
    margin: 0;
  }

  * + * {
    margin-top: 1rem;
  }

  html {
    line-height: 1.15;
    font-size: 16px;
    background-color: #fdfdfd;
    -webkit-text-size-adjust: 100%;
  }

  body,
  html {
    margin: 0;
    font-family: "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto",
      "Helvetica Neue", "Arial", "sans-serif", "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px;
    line-height: 1.4;
    color: ${styles.colors.gray900};

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 18px;
      font-weight: normal;
      line-height: 1.1;
    }
  }
`;

const mainStyles = css`
  margin: 2rem auto 4rem;
  max-width: 90%;
  width: 900px;
`;

const Layout = ({ children }) => {
  return (
    <>
      <Global styles={globalStyles} />
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
      </Helmet>
      <Header />
      <main css={mainStyles}>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
