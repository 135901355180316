import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Link } from "gatsby";

import styles from "../styles";

const NavLink = styled(Link)`
  color: ${styles.colors.primary};
  font-size: ${styles.text.sizebase};
  font-weight: ${props => props.fontWeight || "normal"};
  line-height: 1;
  margin: 0 0.5rem;
  padding: 0.5rem 0.125rem 0;
  text-decoration: none;

  &:hover,
  &.current-page {
    color: ${styles.colors.primaryVariant};
  }
`;

const Header = () => {
  return (
    <header
      css={css`
        display: flex;
        justify-content: space-between;
        padding: 1rem calc((100% - 900px - 1.5rem) / 2);
      `}
    >
      <NavLink
        to="/"
        fontWeight="400"
        css={css`
          font-size: ${styles.text.size2xl};
        `}
      >
        Conor Fischer
      </NavLink>
      <nav
        css={css`
          margin-top: 0.375rem;
        `}
      >
        <NavLink to="/" activeClassName="current-page">
          about
        </NavLink>
        <NavLink to="/blog" activeClassName="current-page">
          blog
        </NavLink>
      </nav>
    </header>
  );
};

export default Header;
